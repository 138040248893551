<template>
  <div>
    <rule @result="createRule" text="Crear" :key="keyFormRule"></rule>
  </div>
</template>
<script>
import Rule from './Rule'
import { environment } from '@/environments/environment'
import TicketManagementService from '../../ticketManagement.service.js'
export default {
  components: {Rule},
  data () {
    return {
      keyFormRule: 1,
      ticketManagementService: new TicketManagementService(this)
    }
  },
  methods: {
    createRule (queryParams) {
      this.ticketManagementService.callService('saveRule', queryParams)
        .then(response => {
          this.$success('La regla ha sido creada correctamente')
          this.m_openWinBlank(`/detail-ticket-rules/${response.data.id}`)
          this.keyFormRule++
        })
        .catch(err => {
          const text = 'Ocurrió un problema al modificar la regla'
          let textMessage = ''
          if (Array.isArray(err)) {
            textMessage += '<ul style="list-style-type: disc;">'
            err.forEach(element => {
              textMessage += `<li>${element.message}</li>`
            })
            textMessage += '</ul>'
          }
          this.$alert(textMessage ? textMessage : text)
        })
    }
  }
}
</script>
<style lang="">
  
</style>